.skeleton-placeholder {
  background: linear-gradient(90deg, #EFF0F7 0px, #F8F8F8 40px, #EFF0F7 80px);
  width: 100%;
  height: 1.45rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  animation: animation 6s infinite;
  &.square{
    border-radius: 2px;
  }
}

@keyframes animation {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 270px;
  }
}
