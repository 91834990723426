.modal-edit-inventory{
    .modal-content {
        background: #FCFCFC;
        border-radius: 6px!important;
        align-items: center;
        width: 484px;
        display: flex;
        flex-direction: column;
    }
    .modal-body {
        padding: unset!important;;
    }
    .body{
        background: #FCFCFC;
        border-radius: 8px!important;
        align-items: center;
        width: 484px;
        display: flex;
        flex-direction: column;
        .header{
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            letter-spacing: 1px;
            color: #000000;
        }
        .content{
            width: 484px;
            font-weight: 400!important;
            color: #68717A!important;
            text-align: left;
            padding: 16px 32px!important;
            .input-text{
                color: #052C65!important;
                background-color: #FFFFFF!important;
                border: 1px solid #CED4DA;
            }
            .input-text:disabled{
                color: #052C65!important;
                background-color: #DEE2E6!important;
                border: 1px solid #CED4DA;
            }
        }
        .action{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 40px;
            width: 484px;
            .button-action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 7px 48px;
                width: 149px;
                color: #FFFFFF;
                background: rgba(13, 110, 253, 1);
                border-radius: 2px;
                border: unset!important;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-cancel{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 7px 48px;
                width: 149px;
                color: #0D6EFD;
                background: rgba(128, 189, 255, 0.5);
                border: unset!important;
                border-radius: 2px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .button-action:hover{
                background: rgba(13, 110, 253, 0.8);
            }
            .button-cancel:hover{
                background: rgba(128, 189, 255, 0.4);
            }
            .button-action:disabled{
                cursor: auto!important;
            }
            .button-cancel:disabled{
                cursor: auto!important;
            }
        }
    }
}

.modal-active-inactive-inventory{
    .modal-content {
        background: #FCFCFC;
        border-radius: 8px!important;
        justify-content: center;
        align-items: center;
        width: 620px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px 0px 25px;
    }
    .modal-body {
        padding: unset!important;
    }
    .body{
        width: 620px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .header{
            width: 306px;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 1px;
            color: #14142B;
        }
        .content{
            padding: 10px 10px 8px;
            width: 540px;
            margin-top: 40px;
            overflow: hidden;
            .notification{
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 1px;
                color: #14142B;
            }
            .list-inventory{
                max-height: 219px;
                overflow-y: scroll;
                margin-top: 24px;
                ul {
                    list-style: none;
                }
                ul li::before {
                    content: "\2022";
                    color: #002EB4;
                    font-weight: bold;
                    display: inline-block; 
                    width: 1em;
                    margin-left: -3em;
                }
                li:hover{
                    color: #3366ff;
                    cursor: pointer;
                }
                .item{
                    margin: 5px 0;
                    color: #002EB4;
                    font-weight: 400;
                    font-size: 12px;
                    letter-spacing: 1px;
                    text-decoration-line: underline;
                }
            }
            .list-inventory::-webkit-scrollbar {
                width: 8px;
            }
            .list-inventory::-webkit-scrollbar-track {
                width: 15px;
                box-shadow: inset 1px 0px 0px #E8E8E8, inset -1px 0px 0px #F0F0F0;
                background: #FAFAFA;
            }
            .list-inventory::-webkit-scrollbar-thumb {
                background: #C1C1C1; 
                border-radius: 4px;
            }
            .list-inventory::-webkit-scrollbar-thumb:hover {
                background: #a6a6a6;
            }
        }
        .action{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 40px;
            width: 374px;
            height: 40px;
            margin-top: 40px;
            .button-action{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px 16px;
                width: 122px;
                height: 40px;
                color: #FCFCFC;
                border-radius: 8px;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
            }
            .active{
                border: 2px solid #002EB4;
                background: #002EB4;
            }
            .active:hover{
                background: #FCFCFC;
                color: #002EB4;;
            }
            .active:disabled{
                cursor: unset; 
                color: #FCFCFC;
                background: #002EB4;
            }
            .inactive{
                border: 2px solid #ED2E2E;
                background: #ED2E2E;
            }
            .inactive:hover{
                background: #FCFCFC;
                color: #ED2E2E;;
            }
            .inactive:disabled{
                cursor: unset; 
                color: #FCFCFC;
                background: #ED2E2E;
            }
        }
    }
}