.side-button-aida {
  position: fixed;
  right: -30px;
  bottom: 17px;
  transition: 0.5s;
  border-radius: 8px 0 0 8px !important;

  img {
    margin-left: -20px;
  }
}

.side-button-aida-open {
  position: fixed;
  right: 26.5%;
  bottom: 17px;
  transition: 0.3s;
  border-radius: 8px 0 0 8px !important;
  z-index: 0;
  img {
    margin-left: -20px;
  }
}

.side-button-aida:hover {
  right: 0;
}

.wrapper-chat-aida {
  ::-webkit-scrollbar {
    width: 1px;
  }
  .card-aida {
    position: fixed;
    height: 100%;
    min-width: 29%;
    z-index: 10;
  }
}

.wrapper-input-chat {
  margin-top: 20px;
  .form-control {
    border-radius: 8px !important;
  }
  .btn {
    border-radius: 0 8px 8px 0 !important;
  }

  .send-btn {
    border-radius: 50% !important;
    height: 35px;
    width: 35px;
    padding: 0 !important;
    margin-left: 5px;
  }
}

.body-chat-aida {
  height: calc(100% - 120px);
  overflow: auto;
}

.aida-disconnected {
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: red;
  top: 10px;
  border-radius: 50%;
  left: 10px;
}

.aida-connected {
  height: 10px;
  width: 10px;
  position: absolute;
  background-color: green;
  top: 10px;
  border-radius: 50%;
  left: 10px;
}

.loader {
    text-align: center;    
}
.loader span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    background: #303e67;
    border-radius: 10px;
    margin-right: 2px;
    animation: loader 0.8s infinite alternate;
}
.loader span:nth-of-type(2) {
    animation-delay: 0.2s;
}
.loader span:nth-of-type(3) {
    animation-delay: 0.6s;
}
@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}


// Bubble chat
.imessage {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.imessage .card {
  border-radius: 8px;
  line-height: 1.25;
  max-width: 90%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}

.imessage .card::before,
.imessage .card::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}

.card.from-me {
  align-self: flex-end;
  background-color: #11a5ed;
  color: #fff;
  margin: 4px 0;
  pre {
    color: #fff;
    margin-bottom: 0 !important;
  }
}

.card.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #11a5ed;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}

.card.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.card[class^="from-"] {
  margin: 0.5rem 0;
  width: fit-content;
}

.card.from-me ~ .card.from-me {
  margin: 0.25rem 0 0;
}

.card.from-me ~ .card.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}

.card.from-me ~ .card.from-me:last-child {
  margin-bottom: 0.5rem;
}

.card.from-them {
  background-color: #e5e5ea;
  color: #000;
  margin: 4px 0;
}

.card.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}

.card.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}

.card[class^="from-"].emoji {
  background: none;
  font-size: 2.5rem;
}

.card[class^="from-"].emoji::before {
  content: none;
}

.chat-hour {
  font-size: 9px;
  margin-top: 10px;
  opacity: 0.4;
  justify-content: end;
  width: 100%;
  display: flex;
}

.chat-date {
  position: sticky;
  top: 10px;
  z-index: 1000;
  background-color: lightgrey;
  border-radius: 20px;
  margin: 7px 120px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.content-title {
  line-height: 1.2;
  font-family: "Roboto",sans-serif;
  font-size: .75rem;
  font-weight: 400;
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    line-height: 1.2;
    font-family: "Roboto",sans-serif;
    font-size: .75rem;
    font-weight: 400;
    margin-bottom: 0 !important;
    a {
      color: #7680ff;
      text-decoration: revert;  
    }
  }
}