.modal-body-scroll-compare-workshop {
    max-height: calc(100vh - 120px); /* Adjust this value based on your needs */
    overflow-y: auto;
  }
  
.modal-content-compare-workshop {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

.modal-header-compare-workshop {
  flex-shrink: 0;
}

.modal-body-compare-workshop {
  flex-grow: 1;
  overflow: auto;
}

.modal-header-custom-compare-workshop {
  border-bottom: none;
  padding: 1rem;
}

.modal-header-custom-compare-workshop .modal-title-compare-workshop {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
