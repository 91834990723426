.btn-thin {
    height: 23px;
    font-size: 10px;
    display: flex;
    align-items: center;
}

.service-tab {
    padding-top: 1.25rem;
}

.label-required {
    &:after {
        content:"*";
        color: red;
    }
}

.detailField2 {
    .label {
        color: #CED4DA;
    }

    .extra {
        font-weight: 500;

        &.text-price > dd {
          color: #198754 !important;
        }
        &.text-total {
          color: #6C757D;
          font-weight: 700;
        }
    }
}

.divider-label {
    color: $light_gray;
    display: flex;
    align-items: center;
    margin: 1rem 0;

    &:after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: $light_gray;
        margin-left: 1rem;
    }
}

.divider-no-label {
    color: $light_gray;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin: 1rem 0 2rem 0;

    &:after {
        content: "";
        flex: 1;
        height: 1px;
        background-color: $light_gray;
        margin-left: 0;
    }
}

.otopoints-checkbox {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);

    .form-check-input {
        margin-top: 3px;
    }
}

.booking-detail-bengkel {
    font-size: 14px;
}

.abuser-notif {
    position: absolute;
    float: right;
    width: 110px;
    top: 0px;
    right: 0px;
    text-align: center;
    img{
        width: 25px;
        opacity: 0.9;   
    }
    p{
        opacity: 0.9;
        color: red;
        padding: 0px!important;
        margin: 0px!important;
        font-size: 13px;
        font-weight: bold;
    }
}

.large-abuser-notif {
    position: absolute;
    width: 150px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.07;  
}

.col-summary{
    display: flex;
    flex-direction: column;
    text-align: left;

    span{
        margin-bottom: 5px;
    }
    .price {
        text-align: right!important;
        font-weight: bold;
    }
    .price-settlement {
        text-align: left!important;
        font-weight: bold;
    }
    .discount-otopoints {
        color: red!important;
    }
    .promo {
        width: auto;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    .grand-total {
        color: green!important;
    }
    .tab{
        padding-left: 1rem;
        display: inline-block;
    }
    .label {
        color: #CED4DA;
    }
    .note-footer{
        font-style: italic;
    }
    .download-spk{
        cursor: pointer;
        text-decoration: underline;
        color: #6610f2;
    }
    .flag {
        font-weight: bold;
        margin-right: 5px;
        color: red;
    }
    .icon-flag {
        width: 16px;
    }
    .divider {
        color: $light_gray;
        display: flex;
        align-items: center;
        margin: 1rem 0;
    
        &:after {
            content: "";
            flex: 1;
            height: 1px;
            background-color: $light_gray;
        }
    }
    .group{
        color: #6C757D;
        // color: #6C757D;
        color: #000000;
        font-weight: 700!important;
    }
    
    .detail-group{
        color: #212529;
        font-weight: 700!important;
    }
    
    .detail-group-service{
        color: #212529;
        font-weight: 700!important;
        font-size: 11px!important;
    }
    
    .detail-group-item-name{
        color: #212529;
        font-weight: 400!important;
        font-size: 11px!important;
    }
    
    .detail-group-item-qty{
        color: #6C757D;
        font-weight: 400!important;
        font-size: 10px!important;
        display: flex;
        align-items: center;
    }

    .remarks-item{
        color: #6C757D;
        font-weight: 400!important;
        font-size: 10px!important;
        overflow-wrap: break-word;
    }

    .detail-group-promo-name{
        color: #46c54a;
        font-weight: 700!important;
    }

    .divider-no-mt{
        margin-top: unset!important;
    }
}

.promo-name{
    color: green;
    font-weight: 700!important;
}

.download-spk{
    cursor: pointer;
    color: #0D6EFD;
    font-weight: 500!important;
    text-decoration: underline;
}

.container-item {
    margin-bottom: 25px;
    background: rgba(224, 207, 252, 0.1);
    border: 1px solid rgba(195, 161, 250, 0.9);
    border-radius: 0.25rem;
    padding-bottom: 0px!important;
    .title-item {
        font-weight: bold;
        width: fit-content;
        height: 10px;
        padding-right: 2px;
        padding-left: 2px;
        margin-top:-22px;
        background: white;
    }
    .close-item {
        cursor: pointer;
        width: 22px;
        margin-top: -13px;
        margin-right: 5px;
        position:absolute;
        top:0;
        right:0;
    }
}

.container-item-revise {
    margin-bottom: 25px;
    background: rgba(224, 207, 252, 0.2);
    border: 1px solid #6610F2;
    border-radius: 0.25rem;
    padding-bottom: 0px!important;
    .title-item {
        font-weight: bold;
        width: fit-content;
        height: 10px;
        padding-right: 2px;
        padding-left: 2px;
        margin-top:-22px;
        background: white;
    }
    .close-wrapper{
        cursor: pointer;
        width: 28px;
        height: 24px;
        border: 1px solid #D6D8E7;
        border-radius: 4px;
        background-color: #FFFFFF;
        margin-top: -13px;
        margin-right: 5px;
        position:absolute;
        top:0;
        right:0;
        .icon{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 13px;
        }
    }
}

.border-revise-rejected {
    border: 1px solid red!important;
}

.border-revise-approved {
    border: 1px solid green!important;
}

.btn-add-item-package {
    font-size: .85rem;
    background-color: white;
    font-weight: bold;
    border: 1px solid #6610F2;
    border-radius: 100px;
    color: black;
    padding: 5px;
    padding-right: 1rem;
  }

  .icon-trash-spk{
    object-fit: unset!important;
    position: absolute!important;
    margin-left: 5px!important;
    margin-top: 1px!important;
    cursor: pointer;
    width: unset!important;
    height: 17px!important;
}

.document-area{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 20px;
    width: 100%;
}

.document-box-preview{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 2px solid #DEE2E6;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
    .file{
        width: 100%;
        cursor: pointer;
        color: #0D6EFD;
        display: inline-block;
        font-weight: 500!important;
        text-decoration: underline;
    }
    .choose-file{
        color: #6C757D;
        display: inline-block;
        font-weight: 500!important;
    }
    .icon-trash-workshop{
        cursor: pointer;
        width: unset!important;
        height: 15px!important; 
    }
}

.btn-export-finance {
    color: #ffffff;
    background-color: #6610f2;
    border: 1px solid #6610f2;
    // border-bottom: 20px;
    margin: 0px 5px 0px 0px;
    &:hover{
        background-color: #4c03c1;
        border: 1px solid #6610f2;
    }
}

.modal-export-finance {
    .header-finance{
        margin: 0px 0px 30px 0px;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        letter-spacing: 1px;
        color: #000000;
    }
    .action{
        .button-action{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 7px 48px;
            width: 149px;
            color: #FFFFFF;
            background: rgba(13, 110, 253, 1);
            border-radius: 2px;
            border: 1px solid rgba(13, 110, 253, 1);
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 1px;
        }
        .button-action:hover{
            border: 1px solid rgba(13, 110, 253, 1);
            background: white;
            color: rgba(13, 110, 253, 1);
        }
        .button-action:disabled{
            cursor: auto!important;
        }
    }
}

.section-input{
    .label-date{
        color: #343A40;
        word-spacing: 1px;;
        background-color: #000000;
    }
    .react-datepicker-wrapper{
        width: 230px;
        .date-field-input{
            width: 100%;
            height: 30px;
            border: 1px solid #E0E7ED;
            background-color: #FFFFFF;
            border-radius: 5px;
            background-image: url('../assets/icons/calender.svg');
            background-repeat: no-repeat;
            background-position: 200px 5px;
            padding: 8px 8px 8px 8px;
            color: #052C65!important;
            font-weight: 500!important;
            &:hover{
                cursor: pointer;
            }
            &:focus, &.focus, &.filled{
                border: 1px solid #6610F2!important;
                background-image: url('../assets/icons/calender-purple.svg');
            }
            &:disabled{
                background-color: #FFFFFF!important;
                cursor: unset;
            }
            
        }
    }
    .react-datepicker__close-icon{
        &::after{
            background-color: #6610F2;
        }
    }
    .react-datepicker__tab-loop{
        position: absolute;
    }
    .react-datepicker-popper{
        padding-top: 7px;
        .react-datepicker{
            padding-top: 15px;
            background-color: #FFFFFF;
            border: 0.5px solid #6610F2;
            box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
            border-radius: 6px;
        }
        .react-datepicker__triangle{
            &::after, &::before{
                border: unset;
                content: unset;
            }
        }
        .react-datepicker__navigation{
            top: 14px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--previous{
            left: 0px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--next{
            right: 0px;
        }
        .react-datepicker__month-container{
            padding: 0px 10px 0px 10px
        }
        .react-datepicker__header{
            background-color: #FFFFFF;
            border-bottom: unset;
            padding: 0px;
            .react-datepicker__current-month{
                margin: 0px 10px;
                font-size: 14px;
                font-family: sans-serif;
                letter-spacing: 0.5px;
                color: #212529;
            }
            .react-datepicker__day-names{
                margin-bottom: 5px;
            }
            .react-datepicker__day-name{
                font-weight: 600;
            }
            .react-datepicker__header__dropdown--select{
                padding-bottom: 35px;
                border-bottom: 1px solid #DEE2E6;
                margin-top: 10px;
            }
            .react-datepicker__month-dropdown-container--select{
                position: absolute;
                left: 0;
            }
            .react-datepicker__year-dropdown-container--select{
                position: absolute;
                right: 0;
            }
            .react-datepicker__month-select, .react-datepicker__year-select{
                border: unset;
                color: #6610F2;
                font-weight: 600;
                border: 1px solid #6610F2;
                height: 25px;
                border-radius: 6px;
                cursor: pointer;
            }
            .select-items{
                display: block;
            }
            .select-hide {
                display: none!important;
            }
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            font-family: sans-serif;
            color: #212529;
            font-weight: 500;
            margin: 2px 0px;
            width: 2rem;
            line-height: 2.5rem;
        }
        .react-datepicker__month{
            margin: 0rem 0.4rem 0.4rem 0.4rem;
            .react-datepicker__week{
                .react-datepicker__day{
                    &:hover{
                        color: #212529;
                        background-color: #E0CFFC;
                    }
                }
                .react-datepicker__day--today{
                    font-weight: 700;
                }
                .react-datepicker__day.react-datepicker__day--keyboard-selected {
                    background: none;
                    color: black;
                }
                .react-datepicker__day.react-datepicker__day--selected {
                    border-radius: 6px;
                    color: #FFFFFF;
                    background-color: #6610F2;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #6610F2;
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-range, .react-datepicker__day.react-datepicker__day--in-selecting-range{
                    color: #212529;
                    background-color: #E0CFFC;
                    border-radius: 0px;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start, .react-datepicker__day.react-datepicker__day--range-start, .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 6px 0px 0px 6px;
                    color: #FFFFFF;
                    background-color: #6610F2;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #6610F2;
                    }
                }
                .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 0px 6px 6px 0px;
                }
                .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end {
                    border-radius: 6px;
                }

                //outside month
                .react-datepicker__day.react-datepicker__day--outside-month{
                    color: #CED4DA;
                    &:hover{
                        background-color: #CED4DA;
                        color: #212529
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month{
                    background-color: #CED4DA;
                    color: #212529;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--outside-month {
                    color: #FFFFFF;
                    background-color: #838485;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #838485;
                    }
                }
                //end of outside month
            }
        }
        .react-datepicker__today-button {
            background-color: unset;
            border-top: 1px solid #DEE2E6;
            font-weight: 400;
            font-size: 14px;
            color: #6610F2;
            font-family: sans-serif;
            letter-spacing: 0.5px;
            margin: 0px 10px 0px 10px;
            padding: 5px 0px 8px 0px;
            &:hover{
                font-weight: 600;
            }
        }
    }
}

.otx-take-rate {
    border: 1px dashed #ced4da;
    border-radius: 8px;
}

.customer-bill-amount {
    border: 1px dashed #ced4da;
    border-radius: 8px;
    background-color: #d5e8d4;
}

.btn-override-price {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 30px;
    margin-top: 3px;
    padding: 3px 10px 3px 10px;
    color: #ffffff;
    background-color: #6610f2;
    border: 1px solid #6610f2;
    &:hover {
        background-color: #a4acfc;
        border: 1px solid #a4acfc;
    }
    &:focus, &:active {
        &:not([disabled]) {
            background-color: #6610f2;
            border: 1px solid #6610f2;
        }
    }
}

.btn-no-override-price {
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 30px;
    margin-top: 3px;
    padding: 3px 10px 3px 10px;
    color: #ffffff;
    background-color: #a4acfc;
    border: 1px solid #a4acfc;
    &:hover{
        background-color: #6610f2;
        border: 1px solid #6610f2;
    }
    &:focus, &:active {
        background-color: #a4acfc;
        border: 1px solid #a4acfc;
    }
    &:disabled {
        &:hover {
            cursor: default;
            background-color: #7680ff;
            border: 1px solid #7680ff;
        }
    }
}

.override-icon {
    filter:  brightness(0) invert(1);
}
.container-upload-file {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 20px;
    width: 100%;
    margin-top: 10px;
  
    .box {
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 20px;
      position: relative;
      width: 209px;    
  
      img {
        object-fit: cover;
        width: 209px;    
        height: 149px;
        border: 1px solid #ADB5BD;
        border-radius: 2px;
      }

      h1 {
        font-size: 13px;
        font-weight: 700;
        margin: 0;
        line-height: 25px;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    
      h2 {
        font-size: 13px;
        font-weight: 400;
        margin: 0;
        line-height: 20px;     
        overflow: hidden;
      }

      .all-scroll{
        cursor: all-scroll;
      }

      .icon-trash{
        object-fit: unset!important;
        position: absolute!important;
        cursor: pointer;
        width: 30px!important;
        height: 25px!important;
        top: 5px;
        left: 5px;
        border-radius: 2px;
        border: 1px solid red;
        background-color: white;
        background-image: url('../assets/icons/trash.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center center;
    }
      
      .container-cx-round {
        position: absolute;
        left: 5px;
        top: 5px;
  
        .round {
          position: relative;
        }
  
        .round input[type="checkbox"] {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          border: 1px solid #D6D8E7;
          border-radius: 8px!important;
        }
      }
    }

    .box-upload {
        text-align: center;
        background: #F8F9FA;
        border: 2px dashed #ADB5BD;
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        width: 250px;    
        height: 35px;
        padding: 10px;
        margin: 10px;
        cursor: pointer;
        .title{
            // font-weight: 400;
            font-size: 12px;
            color: #212529;
        }
        .detail{
            font-weight: 400;
            font-size: 10px;
            color: #6C757D;
        }
        &.drag-over{
            border: 2px dashed #0D6EFD!important;
        }
    }
  }

  .box.nothing-image {
    img {
        object-fit: cover;
        width: 209px!important;    
        height: 149px!important;
        border-radius: 2px;
        border: 3px dashed #6610F2!important;
    }
  }

  .box.nothing-videos {
    img {
        object-fit: cover;
        width: 209px!important;    
        height: 149px!important;
        border-radius: 2px;
        border: 3px dashed #6610F2!important;
    }
  
    h1 {
      font-size: 13px;
      font-weight: 700;
      margin: 0;
      line-height: 25px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  
    h2 {
      font-size: 13px;
      font-weight: 400;
      margin: 0;
      line-height: 20px;     
      overflow: hidden;
    }
  }

.icon-download {
  cursor: pointer;
  width: 20px;
  margin: 0 7.5px;
}

.legal-docs {
    .container-upload-file {
        @extend .container-upload-file;

        span {
            cursor: pointer;
        }
    }
}