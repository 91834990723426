.dropdown-create-item {
  display: flex;
  cursor: pointer;
  border-top: 1px solid #FD7E14;
  background: #FFE5D0;
  padding: 10px;
  color: #FD7E14;
  font-weight: 700;
  font-size: 1rem;
}

.dropdown-create-product {
  display: flex;
  cursor: pointer;
  color: #FD7E14;
  font-weight: 700;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.required:after {
  content: "*";
  color: #DC3545
}

.profile-picture-wrapper {
  position: relative;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #f1f5fa;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.link:hover{
  text-decoration: underline;
}

.primary-link{
  color: $primary;
  font-weight: 500;
  &.grey-link{
    color: $soft-gray;
  }
}

.attached-profpict {
  max-width: 200px;
  height: auto;
  object-fit: inherit;
}

.top-shadow {
  box-shadow: 0 -10px 18px rgba(0,0,0,.07);
}

.points-card-body {
  height: 500px;
  overflow-y: auto;
  scroll-behavior: smooth;
  .list-points-wrapper {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 0.5rem;
    .badge-status {
      color: $primary;
      font-size: 12px !important;
      background-color: $soft-purple;
      font-weight: 500;
    }
    .date-time {
      font-size: 14px;
      color: $soft-gray;
      span {
        color: $light_gray;
      }
    }
    .description-wrapper {
      .label {
        font-size: 14px;
        color: $light-gray;
      }
      .description {
        font-weight: 400;
      }
    }
    .point {
      color: $primary;
      font-weight: 600;
    }
  }
}

.main-pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  li {
    float: left;
    cursor: pointer;
    padding: 6px 0;
    a {
      margin: 0 2px;
      border: unset;
      font-size: 14px;
      color: $soft-gray;
      padding: 6px 10px;
    }
    &.selected {
      a {
        color: $primary;
        background-color: $soft-purple;
        border-color: unset;
        border: unset;
        border-radius: 6px;
        font-weight: bold;
      }
    }
  }
}

.custom-error {
  .invalid-feedback {
    display: block;
  }
}

input:not([type=checkbox]):not([type=radio]) {
  font-size: 12px;
  // height: calc(1.8em + 0.75rem + 3px) !important;
}

.form-group {
  input[type="checkbox"].form-check-input {
    position: absolute;
    margin-top: 0;
    margin-left: 0;
  }
}

input[type="radio"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid var(--purple);
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
  margin-top: 0;
}
input[type="radio"]:checked {
  background-color: var(--purple);
}

input[type="radio"]:checked:disabled {
  background-color: #b58df6 !important;
}

input[type="radio"]:disabled {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #b58df6 !important;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
}

input.with-suffix {
  border-right: 0px solid;
}

.form-control {
  border: 1px solid #DEE2E6;
}

.input-group-text {
  font-size: .75rem;
  background-color: #ffffff;
  border: 1px solid #DEE2E6;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px solid;
}

.gmap-wrapper {
  .gmnoprint {
    display: none;
  }
}

.workshop-select {
  width: 100%;
  min-height: 100px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #CED4DA;
  border-radius: 8px;

  img.arrow {
    padding: 8px;
    border-left: 1px solid #CED4DA;
  }
}

.workshop-select-disabled {
  background-color: #DEE2E6;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #CED4DA;
  border-radius: 8px;

  img.arrow {
    padding: 8px;
    border-left: 1px solid #CED4DA;
  }
}

.btn-add-item {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 3px 10px 3px 3px;
}

.booking-service-detail-tabs {
  margin-bottom: 21px;
}

.npwp_form-values {
  padding: 7px 10px;
}

.container-tag {
  display: flex;
  height: 100%;
  max-height: fit-content;
  flex-grow: 1;
  flex-wrap: wrap;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 1px #CED4DA solid;
  border-radius: 5px;
  color: black;
  &.disabled {
    background-color: #DEE2E6;
  }
  &.focus {
    border: 1px #6610F2 solid;
  }
  input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #BDBDBD;
    font-weight: 200!important;
  }
}

.container-tag input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 4px;
  padding: 14px;
  padding-left: 5px;
  &:disabled {
    background-color: #DEE2E6 !important;
  }
}

.tag {
  cursor: default;
  word-wrap: break-word;
  align-items: center;
  margin: 5px 0px 0 5px;
  padding: 0px 0px 0px 5px;
  margin-bottom: 7px;
  border: none;
  border-radius: 4px;
  background-color: #6610F2;
  color: white;
  height: 22px;
  font-size: 10px;
  &.disabled {
    background-color: #68717A;
  }
  .tag-text {
    height: 100%;
    padding: 0 4px 2px 2px;
  }
}

.tag button {
  font-size: 12px;
  padding: 0px 10px 0px 10px;
  border: none;
  background-color: unset;
  height: 100%;
  cursor: pointer;
  color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  &:hover {
    background-color: #dcc5f9;
    color: #6610F2;
  }
  &.disabled {
    background-color: #68717A;
    color: #68717A;
    &:hover {
      background-color: #68717A;
      color: #68717A;
      cursor: default;
    }
  }
}
