.menu-resp {
  display: none;
}

.hamburger-menu {
  font-size: 32px;
}

.gmap-autocomplete-wrapper {
  position: absolute;
  width: calc(100% - 19px);
  left: 10px;
  right: 10px;
  top: 11px;

  .disabled-input {
    width: 100%;
    height: 38px;
    background-color: #000;
    opacity: 10%;
    position: absolute;
    top: 0;
  }
}

.navbar-custom-staging {
  background-color: #add8e6; // Light blue color for staging
}

@media screen and (max-width: 1024px) {
  .topbar {
    .navbar-custom {
      margin-left: 0;
    }
  }

  .left-sidenav {
    position: fixed;
    border-right: 1px solid #d9d9d9;
    box-shadow: 2px 0 3px 0 #d9d9d9;

    .left-sidenav-menu {
      height: auto !important;
    }
  }

  .menu-resp {
    display: block;
  }

  .hidden-resp {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .left-sidenav {
    position: fixed;
    height: auto;
    float:left;
    padding:0 0 0 5px;
    float:left;
  }

  .left-sidenav-menu {
    height: 82vh !important;  
    overflow-y: auto !important;
    margin-left:-5px;
    padding-bottom: 30px;
  }

}

@media screen and (max-width: 767px) {
  .logo-rv {
    display: none;
  }
  .breadcrumb {
    display: flex!important;
  }
  .tab-item{
    width: 100%;
  }
  .section-pagination{
    .section-row-per-page{
        width: 100%!important;
        justify-content: center;
    }
    .section-info-paging{
        width: 100%!important;
        text-align: center!important;
    }
    .section-paging{
        width: 100%!important;
        text-align: center!important;
        margin-top: 10px;
    }
  }
}

@media screen and (max-width: 576px) {
  .col-form-label {
    text-align: left;
  }

  .user-car-detail {
    width: 300px;
  }

  .wrapperContent {
    header {
      padding: 5px;

      .form-inline {
        width: 100%;

        .form-group {
          width: 100%;
        }

        .width-250 {
          width: 100% !important;
        }
      }
    }
  }

  .section-action {
    .button-action{
      width: 100%!important;
    }
  }
  .section-filters{
    .text-field-filter{
      width: 100%!important;
    }
    .select-field-filter{
      width: 100%!important;
    }
    .text-field-range-price-filter{
      width: 100%!important;
      .content-range-price{
        width: 100%!important;
        .wrapper-range-price{
          flex-direction: column!important;
        }
      }
    }
    .select-text-field-filter{
      width: 100%!important;
    }
    .react-datepicker-wrapper{
      width: 100%!important;
    }
    .button-reset-filter{
      width: 100%!important;
    }
  }

  .card-product-list {
    padding: 12px !important;
  }
}