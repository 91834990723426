.dropup-menu {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .dropup-menu.open {
    max-height: 200px; /* Set this to the max height of your menu items */
  }

  