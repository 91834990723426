.equal-width-columns th,
.equal-width-columns td {
  min-width: 280px; /* Adjust this value as needed */
  width: 1%;
}

.equal-width-columns th:first-child,
.equal-width-columns td:first-child {
  min-width: 150px; /* Adjust this value for the first column */
}

.equal-width-columns th:nth-child(2),
.equal-width-columns td:nth-child(2) {
  min-width: 100px; /* Adjust this value for the second column */
}

.table-responsive {
  overflow-x: auto;
}

@media (max-width: 768px) {
  .equal-width-columns th,
  .equal-width-columns td {
    min-width: 120px; /* Smaller minimum width for mobile devices */
  }

  .equal-width-columns th:first-child,
  .equal-width-columns td:first-child {
    min-width: 150px; /* Smaller minimum width for the first column on mobile devices */
  }
}
